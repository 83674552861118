import React, { lazy } from "react";
import { Redirect } from "react-router-dom";

// Layouts
const Side               = lazy(() => import("./layouts/Side"));
const SideNoLoginCheck   = lazy(() => import("./layouts/SideNoLoginCheck"));
const SideFullScreen     = lazy(() => import("./layouts/SideFullScreen"));
const FullScreen         = lazy(() => import("./layouts/FullScreen"));

// Vistas (Lazy loaded)
const Login              = lazy(() => import("./views/front/placeholders/Login"));
const Logout             = lazy(() => import("./views/front/placeholders/Logout"));
const AutoLogin          = lazy(() => import("./views/whatsapp/placeholders/AutoLogin"));
const SelectConversacion = lazy(() => import("./views/front/placeholders/SelectConversacion"));
const Search             = lazy(() => import("./views/front/Search"));
const Home               = lazy(() => import("./views/whatsapp/cliente/ClienteBuscador"));
const ClienteLoader      = lazy(() => import("./views/front/cliente/ClienteLoader"));
const ClienteBuscar      = lazy(() => import("./views/front/cliente/ClienteBuscar"));
const Buscador           = lazy(() => import("./views/whatsapp/cliente/ClienteBuscador"));
const Licenciador        = lazy(() => import("./views/whatsapp/cliente/ClienteLicencias"));
const Bio                = lazy(() => import("./views/front/cliente/Bio"));
const Licencias          = lazy(() => import("./views/front/cliente/Licencias"));
const Reemplazos         = lazy(() => import("./components/front-view/licencias/LicenciasReemplazos"));
const Timbres            = lazy(() => import("./views/front/cliente/Timbres"));
const Relaciones         = lazy(() => import("./views/front/cliente/Relaciones"));

const AgregarCliente     = lazy(() => import("./views/front/cliente/forms/AgregarCliente"));
const EditarCliente      = lazy(() => import("./views/front/cliente/forms/EditarCliente"));
const EditarFacturacion  = lazy(() => import("./views/front/cliente/forms/EditarFacturacion"));
const AsignarTimbres     = lazy(() => import("./views/front/cliente/forms/AsignarTimbres"));
const AgregarLicencia    = lazy(() => import("./views/front/cliente/forms/AgregarLicencia"));
const ReemplazarLicencia = lazy(() => import("./views/front/cliente/forms/ReemplazarLicencia"));
const EditarSeguimiento  = lazy(() => import("./views/front/cliente/forms/EditarSeguimiento"));
const AgregarCorreo      = lazy(() => import("./views/front/cliente/forms/AgregarCorreo"));
const AgregarRFC         = lazy(() => import("./views/front/cliente/forms/AgregarRFC"));
const AgregarTelefono    = lazy(() => import("./views/front/cliente/forms/AgregarTelefono"));
const AgregarFacebook    = lazy(() => import("./views/front/cliente/forms/AgregarFacebook"));
const AgregarTwitter     = lazy(() => import("./views/front/cliente/forms/AgregarTwitter"));
const MergeClientes      = lazy(() => import("./views/front/cliente/forms/MergeClientes"));
const ReporteBasecamp    = lazy(() => import("./views/front/cliente/ReporteBasecamp"));
const SoporteAvanzado    = lazy(() => import("./views/front/cliente/SoporteAvanzado"));
const AgregarUsuario     = lazy(() => import("./views/front/management/forms/AgregarUsuario"));
const CambiarPassword    = lazy(() => import("./views/front/management/forms/CambiarPassword"));
const Cuenta             = lazy(() => import("./views/front/management/Cuenta"));
const Administrador      = lazy(() => import("./views/front/management/Administrador"));

const Cliente            = lazy(() => import("./views/front/cliente/Cliente"));
const Whatsapp           = lazy(() => import("./views/whatsapp/Whatsapp"));
const InvoicePreview     = lazy(() => import("./components/front-view/transacciones/InvoicePreview"));
const Reportes           = lazy(() => import("./views/front/reportes/TransactionHistory"));
const Devoluciones       = lazy(() => import("./views/front/reportes/ChargebackHistory"));
const Facturas           = lazy(() => import("./views/front/reportes/InvoiceHistory"));
const Rfcs               = lazy(() => import("./views/front/reportes/RfcHistory"));
const Transacciones      = lazy(() => import("./views/front/reportes/ReportePagoSoporte"));


export default [
  {
    path: "/",
    exact: true,
    layout: SideNoLoginCheck,
    component: () => <Redirect to="/login" />
  },
  {
    path: "/.well-known/change-password",
    exact: true,
    layout: SideFullScreen,
    component: () => <Redirect to="/side/management/account" />
  },
  {
    path: "/login",
    layout: SideNoLoginCheck,
    component: Login
  },
  {
    path: "/logout",
    layout: SideNoLoginCheck,
    component: Logout
  },
  {
    path: "/auto_login",
    layout: SideNoLoginCheck,
    component: AutoLogin
  },
  {
    path: "/side/select_conversacion",
    layout: Side,
    component: SelectConversacion
  },
  {
    path: "/side/search",
    layout: SideFullScreen,
    component: Search
  },
  {
    path: "/side/home",
    layout: Side,
    component: Home
  },
  {
    path: "/side/loader",
    layout: Side,
    component: ClienteLoader
  },
  {
    path: "/side/buscar",
    layout: Side,
    component: ClienteBuscar
  },
  {
    path: "/side/cliente/buscador",
    layout: Side,
    component: Buscador
  },
  {
    path: "/side/licencia/buscador",
    layout: Side,
    component: Licenciador
  },
  {
    path: "/side/cliente/bio",
    layout: Side,
    component: Bio
  },
  {
    path: "/side/cliente/licencias",
    layout: Side,
    component: Licencias
  },
  {
    path: "/side/cliente/reemplazos",
    layout: Side,
    component: Reemplazos
  },
  {
    path: "/side/cliente/timbres",
    layout: Side,
    component: Timbres
  },
  {
    path: "/side/cliente/relaciones",
    layout: Side,
    component: Relaciones
  },
  {
    path: "/side/cliente/agregar",
    layout: SideFullScreen,
    component: AgregarCliente
  },
  {
    path: "/side/cliente/editar",
    layout: SideFullScreen,
    component: EditarCliente
  },
  {
    path: "/side/cliente/editar_facturacion",
    layout: SideFullScreen,
    component: EditarFacturacion
  },
  {
    path: "/side/cliente/asignar_timbres",
    layout: SideFullScreen,
    component: AsignarTimbres
  },
  {
    path: "/side/cliente/agregar_licencia",
    layout: SideFullScreen,
    component: AgregarLicencia,
  },
  {
    path: "/side/management/agregar_usuario",
    layout: SideFullScreen,
    component: AgregarUsuario,
  },
  {
    path: "/confirmation",
    layout: SideNoLoginCheck,
    component: CambiarPassword,
  },
  {
    path: "/side/management/account",
    layout: SideFullScreen,
    component: Cuenta,
  },
  {
    path: "/side/management/admin",
    layout: SideFullScreen,
    component: Administrador,
  },
  {
    path: "/side/cliente/reemplazar_licencia",
    layout: SideFullScreen,
    component: ReemplazarLicencia,
  },
  {
    path: "/side/cliente/editar_seguimiento",
    layout: SideFullScreen,
    component: EditarSeguimiento
  },
  {
    path: "/side/cliente/editar_correo",
    layout: SideFullScreen,
    component: AgregarCorreo
  },
  {
    path: "/side/cliente/agregar_rfc",
    layout: SideFullScreen,
    component: AgregarRFC
  },
  {
    path: "/side/cliente/agregar_correo",
    layout: SideFullScreen,
    component: AgregarCorreo
  },
  {
    path: "/side/cliente/agregar_telefono",
    layout: SideFullScreen,
    component: AgregarTelefono
  },
  {
    path: "/side/cliente/editar_telefono",
    layout: SideFullScreen,
    component: AgregarTelefono
  },
  {
    path: "/side/cliente/agregar_facebook",
    layout: SideFullScreen,
    component: AgregarFacebook
  },
  {
    path: "/side/cliente/editar_facebook",
    layout: SideFullScreen,
    component: AgregarFacebook
  },
  {
    path: "/side/cliente/agregar_twitter",
    layout: SideFullScreen,
    component: AgregarTwitter
  },
  {
    path: "/side/cliente/editar_twitter",
    layout: SideFullScreen,
    component: AgregarTwitter
  },
  {
    path: "/side/cliente/merge",
    layout: SideFullScreen,
    component: MergeClientes
  },
  {
    path: "/side/cliente/reporte_basecamp",
    layout: SideFullScreen,
    component: ReporteBasecamp
  },
  {
    path: "/side/soporte_avanzado",
    layout: Side,
    component: SoporteAvanzado
  },
  {
    path: "/side/cliente",
    layout: Side,
    component: Cliente
  },
  {
    path: "/whatsapp/soporte",
    layout: SideFullScreen,
    component: Whatsapp
  },
  {
    path: "/invoice/preview",
    layout: SideFullScreen,
    component: InvoicePreview
  },
  {
    path: "/reporte/finanzas",
    layout: FullScreen,
    component: Reportes
  },
  {
    path: "/reporte/devoluciones",
    layout: FullScreen,
    component: Devoluciones
  },
  {
    path: "/reporte/facturacion",
    layout: FullScreen,
    component: Facturas
  },
  {
    path: "/reporte/rfcs",
    layout: FullScreen,
    component: Rfcs
  },
  {
    path: "/side/transacciones",
    layout: Side,
    component: Transacciones
  }
];
